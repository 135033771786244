/* eslint-disable import/no-dynamic-require */
import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import {
  PRODUCT_NAMES,
  COLOR,
  BREAKPOINT,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import CardTile from '../../contact-us/components/_CardTile';
import formData from '../data/forms.json';

export default () => {
  const { conditionsOfUse } = formData;

  const cardData = addTracking([
    {
      id: 'latitude-28-degrees-forms',
      title: PRODUCT_NAMES['28D'],
      image: '28-global-platinum-front.png',
      cardImageOrientation: 'vertical',
      links: [conditionsOfUse.latitude28Degrees],
      productid: ['CCAU28D']
    },
    {
      id: 'latitude-infinity-rewards-forms',
      title: 'Latitude Infinity Rewards Visa',
      image: 'infinity-rewards-front.png',
      cardImageOrientation: 'vertical',
      links: [conditionsOfUse.latitudeInfinityRewards],
      productid: ['CCAUINF']
    },
    {
      id: 'latitude-low-rate-mastercard-forms',
      title: 'Latitude Low Rate Mastercard<sup>®</sup>',
      image: 'latitude-low-rate-front.png',
      cardImageOrientation: 'vertical',
      links: [conditionsOfUse.latitudeLowRateMastercard],
      productid: ['CCAULRM']
    },
    {
      id: 'latitude-gem-visa-forms',
      title: PRODUCT_NAMES.GEM,
      image: 'latitude-gem-front.png',
      cardImageOrientation: 'vertical',
      links: [conditionsOfUse.gemVisa],
      productid: ['CCAUGEM']
    },
    {
      id: 'latitude-go-mastercard-forms',
      title: 'Latitude GO Mastercard<sup>®</sup>',
      image: 'go-mastercard.png',
      cardImageOrientation: 'vertical',
      links: [conditionsOfUse.goMastercard],
      productid: ['CCAUGOM']
    },
    {
      id: 'latitude-credit-line-afs-forms',
      title: 'CreditLine - acquired at <br/>Apple',
      image: 'creditline-afs.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.creditLineAFS],
      productid: ['CCAUAFS']
    },
    {
      id: 'latitude-mastercard-forms',
      title: 'Latitude Mastercard<sup>®</sup>',
      image: 'latitude-mastercard.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.latitudeMastercard],
      productid: ['CCAULMC']
    },
    {
      id: 'latitude-eco-mastercard-forms',
      title: 'Latitude Eco Mastercard<sup>®</sup>',
      image: 'latitude-eco-mastercard.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.latitudeEcoMastercard],
      productid: ['CCAUECO']
    },
    {
      id: 'latitude-credit-line-au-forms',
      title: 'CreditLine',
      image: 'creditline.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.creditLineAU],
      productid: ['CCAUAFF']
    },
    {
      id: 'latitude-buyers-edge-forms',
      title: 'Buyers Edge',
      image: 'buyers-edge.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.buyersEdge],
      productid: ['CCAUBE']
    },
    {
      id: 'latitude-care-credit-forms',
      title: 'Care Credit',
      image: 'care-credit.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.careCreditCard],
      productid: ['CCAUCCD']
    },
    {
      id: 'latitude-go-business-forms',
      title: 'Go Business Mastercard<sup>®</sup>',
      image: 'go-business.png',
      cardImageOrientation: 'horizontal',
      links: [conditionsOfUse.goBusinessMastercard],
      productid: ['CCAUGBU']
    }
  ]);

  function addTracking(data) {
    return data.map(({ links, ...restItem }) => {
      const linksWithTracking = links.map(link => ({
          ...link
        }));

      return {
        ...restItem,
        links: linksWithTracking
      };
    });
  }

  const Ul = styled.ul`
    width: 100%;
    min-width: 333px;
    list-style-type: none;
    margin: 0;
    padding: 24px;
  `;

  const Li = styled.li`
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 8px;
    }
    a {
      color: ${COLOR.BLUE} !important;
      border-color: ${COLOR.BLUE} !important;
      font-weight: 400;
    }
  `;

  const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: ${BREAKPOINT.MD}) {
    justify-content: space-between;
  }
`;

const GroupItem = styled.div`
  width: 100%;
  max-width: 350px;
  margin-bottom: 2em;
  @media (min-width: ${BREAKPOINT.MD}) {
    flex: 0 0 calc(50% - 15px);
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    flex: 0 0 calc(33.333333% - 15px);
  }
`;

const StyledCardTile = styled(CardTile)`
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
`;

  return (
    <Group>
      {cardData.map(card => (
          <GroupItem>
            <StyledCardTile
              key={card.title}
              image={
                <img
                  src={require(`../../../images/credit-cards/${card.image}`).default}
                  alt={card.title}
                />
              }
              cardImageOrientation={card.cardImageOrientation}
              title={card.title}
              content={
                <Ul>
                  {card.links.map(link => (
                    <Li key={link.title}>
                      <Link
                        {...link}
                        button={BUTTON_STYLE.SECONDARY_INVERSE}
                        trackProductId={card.productid}
                        trackEventData={{
                          location: card.title.replace(/(°|<([^]+)>)/gi, '')
                        }}
                      >
                        {link.title}
                      </Link>
                    </Li>
                  ))}
                </Ul>
              }
            />
          </GroupItem>
        ))}
    </Group>
  );
};
